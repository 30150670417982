export const Inter = {
  Bold : "Inter Bold",// 700
  SemiBold : "Inter SemiBold",// 600
  Medium :  "Inter Medium",// 500
  Regular : "Inter Regular",// 400
}

export const colors = {
    transparent:'transparent',
    black:'#000000',
    blackMain:'#313235',
    blackDark:'#2D2935',
    white:'#FFFFFF',
    primary:'#0D2040',
    danger:'#C5453F',
    grayDarker:'#3A3847',
    grayDark:'#737185',
    grayMedium:'#9D9AAD',
    gray10:'#EEEEEE1A',
    gray:'#CFCDD9',
    graySecundary:'#ECEBF3',
    grayMain:'#EEEEEE',
    grayLight:'#F5F5F5',
    greenDark:'#1ED91E',
    green70:'#1ED91EB2',
    green:'#8EE28E',
    greenDarker:'#0E8A0F',
}

export const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
}