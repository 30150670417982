import styled from 'styled-components';
import { breakpoints, colors } from '../../styles';
import { StepsStyleProps } from './types';

export const ContainerMain = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 644px;
  max-width: 1070px;
  background-color: ${colors.white};
  padding: 24px 8px;
  gap: 32px;
  border-radius: 6px;

  @media (min-width: ${breakpoints.md}px) {
    width: 80vw;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  img {
    width: 187px;
    height: 49px;
  }
`;

export const Body = styled.div<StepsStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(p) => (p.isRow ? 'row' : 'column')};
  width: 100%;
  gap: 12px;
  margin-bottom: 80px;
  animation: fade 0.5s ease both;
  @keyframes fade {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 50px;
  }
`;

export const BodyWrap = styled.div<StepsStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  width: 100%;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    max-width: 384px;

    span {
      color: ${colors.blackDark};
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2%;
    }
    input {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 15px 16px;
      border-radius: 4px;
      border: 1px solid ${colors.gray};
      background-color: ${colors.white};
      font-size: 16px;
    }
    .error {
      color: ${colors.danger};
      text-transform: lowercase;
    }
    .input-wrapper {
      position: relative;
      width: 100%;

      .input-status {
        position: absolute;
        right: 10px;
        top: 16px;
      }
    }
    .terms{
        display: flex;
        flex-direction: row;
        span{
          margin-left: 5px;
          font-weight: 300;
          font-size: 15px;
          cursor: pointer;

            span{
            color: ${colors.greenDarker};
          }
        }
    }
  }

`;

export const Header = styled.div<StepsStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(p) => (p.isCompact ? 0 : 32)}px;
  margin-bottom: ${(p) => (p.isCompact ? 16 : 0)}px;

  .info {
    color: ${colors.blackDark};
    font-family: Inter;
    font-style: normal;
    line-height: auto;
    letter-spacing: 2%;
    p {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    span {
      color: ${colors.grayDark};
      font-size: 16px;
      font-weight: 400;
    }
    .center{
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0px;
    }
  }
  h1 {
    color: ${colors.blackDark};
    font-family: Inter;
    font-style: normal;
    line-height: auto;
    letter-spacing: 2%;
    font-size: 20px;
    font-weight: 600;
    margin-top: 32px;
    text-align: center;
  }
  span {
    margin-top: 4px;
    font-size: 16px;
    color: ${colors.grayDark};
    font-family: Inter;
    font-style: normal;
    line-height: auto;
    letter-spacing: 2%;
    margin-bottom: 32px;
  }
  .progress {
    animation: fade 0.5s ease both;
    @keyframes fade {
      from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
`;

export const Footer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  justify-self: center;
  /* left: 40%; */
  bottom: -40px;
  transform: translate(-50%, -50%);
  color: ${colors.grayDark};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.24px;
  width: 400px;
  @media (max-width: 740px) { 
    bottom: -220px;
    width: 330px;
    font-size: 10px;
    transform: translate(-50%, -0%);

    /* align-items: left;
    justify-self: left;  */
    }
  a {
    text-decoration: none;
    color: ${colors.grayDark};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.24px;
  }
`;

export const LineBroken = styled.div`
  position: absolute;
  display: flex;
  background-color: #999;
  height: 0.2px;
  opacity: 1;
  top: -30px;
  width: 300%;
  @media (max-width: 740px) { 
    width: 100%; 
  }
`
export const Iframe = styled.div`
    border: 1px solid ${colors.greenDarker};
    border-radius: 4px;
    width: 80%;
    height: 280px;
    overflow-y: auto;
    padding: 40px;
    
    h2{
      color: #0d2040;
      font-family: sonoma, Sans-serif;
      font-weight: 600;
      text-align: center;
      margin: 0px 0px 40px 0px;
    }
    .body{
      p{
        margin-block-start: 0;
        margin-block-end: 0.9rem;
        b{
          font-weight: bolder;
        }
      }
      ol{
        margin-block-start: 0;
        margin-block-end: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;

        li{
          margin-block-start: 0;
          margin-block-end: 0;
          border: 0;
          outline: 0;
          font-size: 100%;
          vertical-align: baseline;
          background: transparent;
          ::marker{
            unicode-bidi: isolate;
            font-variant-numeric: tabular-nums;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            
          }
          b{
            font-weight: bolder;
          }
        }
      }
    }
`  
