import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validation } from '../../validations';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { QuizzData } from '../../types';
import { useParams } from "react-router-dom";
import { handleQuizz } from '../../services';
import { links } from '../../constants';
import { Footer, LineBroken } from './style';
import { LogoIcon } from '../../assets';

export default function Home() {
  const [step, setStep] = useState(1);
  const [option, setOption] = useState('');

  const { reset } = useForm({ resolver: yupResolver(validation) });
  // TODO: add confirm params
  const { slug } = useParams();

  const handleNextStep = () => setStep(step + 1);
  const handleLinkSelection = (reponse: string) => {
    setOption(reponse);
    handleNextStep();
  };

  const redirect = (opt:string) =>{
    if (opt === 'yes') {
      if (slug === 'tiktok') window.open(links.yes.tiktok, '_self');
      if (slug === 'google') window.open(links.yes.google, '_self');
      if (slug === 'facebook') window.open(links.yes.facebook, '_self');
    }
    if (opt === 'no') {
      if (slug === 'tiktok') window.open(links.no.tiktok, '_self');
      if (slug === 'google') window.open(links.no.google, '_self');
      if (slug === 'facebook') window.open(links.no.facebook, '_self');
    }
  }

  const onSubmitHandler = async (data: QuizzData, isEmailValid?:boolean) => {
    try {
      // links
      const params = new URLSearchParams(window.location.search);
      console.log('params', params);
      const dataformated = {
        source: slug,
        phone: `55${data?.phone?.replace(/\D/g, '')}`,
        email: data.email,
        fullName: data.name,
        utmSource: params.get('utm_source'),
        utmMedium: params.get('utm_medium'),
        utmCampaign: params.get('utm_campaign'),
        random: Math.floor(Math.random() * 10)
      } as any
      
      if(isEmailValid){
        const response: any = await handleQuizz(dataformated);
        if (response?.result) {
          try {
            TagManager.dataLayer({
              dataLayer: {
                event: 'lead',
                data: dataformated,
              }
            });
            console.log('Dados enviados para o TagManager!');
          } catch (error) {
            console.error('Erro ao enviar os dados para o TagManager!');
          }
        }
      }else{
        console.log('Dados não enviados para o TagManager!');
      }
      redirect(data.option||'');
      reset();
    } catch (error:any) {
      redirect(data.option||'');
      console.error('Erro na solicitação!, tente novamente mais tarde!');
    }
  };

  const steps: Record<number, JSX.Element> = {
    1: <Step1 step={step} onClick={handleNextStep} />,
    2: <Step2 step={step} onClick={(option) => handleLinkSelection(option)} />,
    3: <Step3 step={step} option={option} onSubmit={onSubmitHandler} />,
  };
  return (
    <>
      {steps[step]}

      <Footer>
        <LineBroken /> 
        <div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 15}}>
          <img src={LogoIcon} alt='logo' style={{width: 80, height: 22 }} /> &nbsp; &nbsp; &nbsp;
          ©{new Date().getFullYear()} |  https://ocontabil.com Todos os direitos reservado
          </div>
      
          <div style={{marginBottom: 15}}>
          Go Work Digital LTDA | CNPJ: 41.371.213/0001-07 <br/>
          Belo Horizonte - MG - CEP: 30110-005 - Brasil <br/>
          contato@ocontabil.com | +55 79 9 9917-7197
          </div>
          <div>
            <a href={links.privacy} target='_blank' rel='noreferrer'> Políticas de privacidade </a> |
            <a href={links.terms} target='_blank' rel='noreferrer'> Termos de Serviço </a> |
            <a href={links.disclaimer} target='_blank' rel='noreferrer'> Disclaimer </a>
          </div>
       
        </div>
      </Footer>
    </>
  );
}
