import { colors } from '../../styles';

export default function XCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  const { color = colors.danger, width = 16, height = 16 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_2129)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.34315 13.6569C-0.781049 10.5327 -0.781049 5.46734 2.34315 2.34315C5.46734 -0.781049 10.5327 -0.781049 13.6569 2.34315C16.781 5.46734 16.781 10.5327 13.6569 13.6569C10.5327 16.781 5.46734 16.781 2.34315 13.6569ZM6.03032 4.96967C5.73743 4.67678 5.26256 4.67678 4.96966 4.96967C4.67677 5.26256 4.67677 5.73744 4.96966 6.03033L6.93933 8L4.96966 9.96967C4.67677 10.2626 4.67677 10.7374 4.96966 11.0303C5.26256 11.3232 5.73743 11.3232 6.03032 11.0303L7.99999 9.06066L9.96966 11.0303C10.2626 11.3232 10.7374 11.3232 11.0303 11.0303C11.3232 10.7374 11.3232 10.2626 11.0303 9.96967L9.06065 8L11.0303 6.03034C11.3232 5.73744 11.3232 5.26257 11.0303 4.96968C10.7374 4.67678 10.2626 4.67678 9.96966 4.96968L7.99999 6.93934L6.03032 4.96967Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1_2129'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
