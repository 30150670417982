import { useState, useEffect } from 'react';
import { breakpoints } from '../styles';


export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isScreenMobile = screenWidth < breakpoints.md;
      setIsMobile(isScreenMobile);
    }

    handleResize(); 

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {isMobile};
}

