import axios, { AxiosError } from 'axios';

// const quickEmailApi = axios.create({ baseURL:process.env.QUICK_EMAIL_API_URL });
const quickEmailApi = axios.create({ baseURL:"https://api.quickemailverification.com/v1" });
// console.log('process.env.QUICK_EMAIL_API_URL:', process.env.QUICK_EMAIL_API_URL);
// console.log('process.env.QUICK_EMAIL_API_TOKEN:', process.env.QUICK_EMAIL_API_TOKEN);
quickEmailApi.interceptors.request.use(
    config =>  {
      config.headers['Content-Type'] = 'application/json';
      return  Promise.resolve(config)
    },
    async (error: AxiosError) => Promise.reject(error),
);

quickEmailApi.interceptors.response.use(
    (res) => Promise.resolve(res),
    async (error: AxiosError) => Promise.reject(error),
);

export default quickEmailApi;