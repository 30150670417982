import { colors } from "../../styles";

export default function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
    const { color = colors.greenDarker, width=56, height=57 } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M36.3401 10.2574C33.7223 8.96707 30.7757 8.24219 27.6598 8.24219C16.802 8.24219 8 17.0442 8 27.902C8 38.7597 16.802 47.5617 27.6598 47.5617C38.5176 47.5617 47.3195 38.7597 47.3195 27.902C47.3195 25.3174 46.8208 22.8493 45.9143 20.5887L43.6119 23.083C44.0722 24.6085 44.3195 26.2263 44.3195 27.902C44.3195 37.1029 36.8607 44.5617 27.6598 44.5617C18.4588 44.5617 11 37.1029 11 27.902C11 18.701 18.4588 11.2422 27.6598 11.2422C29.9816 11.2422 32.1925 11.7172 34.2006 12.5752L36.3401 10.2574Z" fill={color} />
            <path d="M22.041 26.4977L27.0964 33.519L47.3179 8.24219" stroke={color} strokeWidth="3"/>
        </svg>
    )
}