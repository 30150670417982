import { ProgressIndicator } from '..';
import { useIsMobile } from '../../hooks';
import { StepsContainer, Step, Steps, Indicator } from './style';
import { ProgressStepsProps } from './types';

export default function ProgressSteps({
  currentStep,
  totalSteps = 3,
}: ProgressStepsProps) {
  const { isMobile } = useIsMobile();
  return (
    <>
      {/* {range(totalSteps).map((step) => {
        const isLastStep = step === totalSteps - 1;
        const isCompleted = (step <= currentStep);
        const isFirstStep = step === 0;
        const isBetween = step > 0 && step < totalSteps - 1;

        const common = {isCompleted, isLastStep, isBetween}
        return (
          <Steps key={step} {...common}>
            {!isFirstStep && (
              <Indicator {...common} />
            )}
            <Step {...common}>{step + 1}</Step>
            {!isLastStep && (
              <Indicator {...common} />
            )}
          </Steps>
        );
      })} */}
      {isMobile ? (
        <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
       ) : (
        <StepsContainer>
          <Steps>
            <Step isCompleted={1 <= currentStep}>{1}</Step>
            <Indicator isCompleted={1 < currentStep} />
          </Steps>
          <Steps>
            <Indicator isBetween isCompleted={1 < currentStep} />
            <Step isCompleted={2 <= currentStep}>{2}</Step>
            <Indicator isBetween isCompleted={3 <= currentStep} />
          </Steps>
          <Steps>
            <Indicator isCompleted={3 <= currentStep} />
            <Step
              isLastStep={3 !== totalSteps - 1}
              isCompleted={3 <= currentStep}
            >
              {3}
            </Step>
          </Steps>
        </StepsContainer>
      )}
    </>
  );
}

