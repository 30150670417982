import styled from 'styled-components';
import { colors } from '../../styles';
import { ButtonFormProps } from './types';

export const ButtonFormContainer = styled.button<ButtonFormProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    min-height: 48px;
    border: ${p => p.isOutlined ? `1.5px solid ${colors.grayDarker}` : 'none'};
    padding: ${p => p.isOutlined ? `12px 10px` : `15px 16px`};
    border-radius: ${p => p.isOutlined ? 4 : 10}px;
    background-color: ${p => p.isOutlined ? colors.white : colors.greenDarker};
    color: ${p => p.isOutlined ? colors.grayDarker : colors.white};
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: ${p=>p.isOutlined ? 500:600};
    line-height: normal;
    letter-spacing: 0.32px;
    text-transform: ${p => p.isOutlined ? 'none' : 'uppercase'};
    overflow-wrap: anywhere;
    :hover{
        background-color:${colors.greenDarker};
        color:${colors.white};
        border:none;
    }
    .load {
        display: flex;
        align-self: center;
        border: 2.8px solid ${colors.white};
        border-top: 2.8px solid ${colors.transparent}; 
        border-radius: 50%;
        width: 28px;
        height: 28px;
        animation: spin 600ms linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

