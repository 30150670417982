import styled from 'styled-components';
import { ProgressStepsStyleProps } from './types';
import { colors } from '../../styles';

const indicatorHeight = 3;
const indicatorSize = 28;

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Steps = styled.div<ProgressStepsStyleProps>`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Step = styled.div<ProgressStepsStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  background-color: ${p=> {
      if(p.isLastStep&&!p.isCompleted) return colors.white;
    if(p.isCompleted) return colors.greenDarker;
    return colors.graySecundary;    
  }};
  border-radius: 100%;
  border: ${p=> p.isLastStep && !p.isCompleted ? `2px solid ${colors.graySecundary}`:'none'};
  color: ${p=>p.isLastStep && !p.isCompleted ?  colors.graySecundary:colors.white};
`;

export const Indicator = styled.div<ProgressStepsStyleProps>`
  width: ${(p) => (p.isBetween ? 50 : 100)}%;
  height: ${indicatorHeight}px;
  background-color: ${p=>p.isCompleted ? colors.greenDarker : colors.graySecundary};
`;
