import { Body, ContainerLogo, ContainerMain, Header } from './style';
import { LogoIcon } from '../../assets';
import { ButtonForm, ProgressSteps } from '../../components';
import { Step1Props } from './types';

export default function Step1(props: Step1Props) {
  const { onClick, step } = props;
  return (
    <ContainerMain>
      <ContainerLogo>
        <img src={LogoIcon} alt='logo' />
      </ContainerLogo>
      <Header>
        <div className='info'>
          <p>Descubra qual é o cartão de crédito ideal para você</p>
          <span>Responda as perguntas a seguir, leva menos de 1 minuto</span>
        </div>
        <div className='progress'>
          <ProgressSteps currentStep={step} />
          <h1>Em qual grupo você se encaixa?</h1>
        </div>
      </Header>
      <Body>
        <ButtonForm
          title='Empregado/Carteira assinada'
          isOutlined
          onClick={onClick}
        />
        <ButtonForm title='Desempregado' isOutlined onClick={onClick} />
        <ButtonForm title='Estudante' isOutlined onClick={onClick} />
        <ButtonForm
          title='Aposentado/Pensionista'
          isOutlined
          onClick={onClick}
        />
        <ButtonForm
          title='Autonômo/Empreendedor'
          isOutlined
          onClick={onClick}
        />

      </Body>
    </ContainerMain>
  );
}
