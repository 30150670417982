import { forwardRef } from 'react';
import { CheckCircleIcon, XCircleIcon } from '../../assets';
import Tooltip from '../tooltip';
import { InputContainer } from './style';
import { InputProps } from './types';
import InputMask from 'react-input-mask';

const Input = forwardRef<HTMLInputElement, InputProps>((
    { 
      label, 
      type, 
      name, 
      error, 
      isError, 
      isCheking, 
      mask, 
      ...rest },
    ref
  ): JSX.Element => {
    const renderInput = (
      <>
        {mask ? (
          <InputMask mask={mask} type={type} name={name} {...rest} />
        ) : (
          <input ref={ref} type={type} name={name} {...rest} />
        )}
      </>
    );
    const inputIcon = (
      <span className='input-status'>
        {!isError ? <CheckCircleIcon /> : <XCircleIcon />}
      </span>
    );

    return (
      <InputContainer>
        {error && <Tooltip left={1} bottom={120} title={error} />}
        <span className='label'>{label}</span>
        {type === 'email' ? (
          <div className='input-wrapper'>
            {renderInput}
            {isCheking && inputIcon}
          </div>
        ) : (
          renderInput
        )}
        {mask && <span className='opcional'>Opcional</span>}
        {/* <span className='error'>
          {error}
        </span> */}
      </InputContainer>
    );
  }
);

export default Input;
