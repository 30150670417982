import * as yup from 'yup';
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const errorMessages = {
  email: {
    required: 'O e-mail não pode ser vazio.',
    min: 'O e-mail deve ter no mínimo 3 caracteres.',
    max: 'O e-mail deve ter no máximo 256 caracteres.',
    email: 'e-mail inválido.',
    matches: 'e-mail inválido.',
  },
  name: {
    required: 'O nome não pode ser vazio.',
  },
  phone: {
    required: 'O telefone não pode ser vazio.',
  },
  option: {
    required: 'O campo não pode ser vazio.',
  },
};

export const validation = yup.object().shape({
  email: yup
    .string()
    .required(errorMessages.email.required)
    .min(3, errorMessages.email.min)
    .max(256, errorMessages.email.max)
    .email(errorMessages.email.email)
    .matches(emailRegex, errorMessages.email.matches),
  name: yup
    .string()
    .required(errorMessages.name.required),
  phone: yup.string().nullable(),
  option: yup.string().required(''),
});
