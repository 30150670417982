import { Body, ContainerLogo, ContainerMain, Form, Header } from './style';
import { LogoIcon } from '../../assets';
import { ButtonForm, Check, Input } from '../../components';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorMessages, validation } from '../../validations';
import { Step3Props } from './types';
import { useState } from 'react';
// import { handleVerifyEmail } from '../../services';
import Terms from './terms';

export default function Step3(props: Step3Props) {
  const { option, onSubmit } = props;
  const [isError,] = useState(false);
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isCheking,] = useState(false);
  const [isAcceptTerm, setIsAcceptTerm] = useState(false);
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  const [isOpenTootip, setIsOpenTootip] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: { option },
    mode: 'onBlur',
  });
  const data = useWatch({ control });

  // const onVerifyEmail = async (email?: string) => {
  //   setIsCheking(false);
  //   try {
  //     const verify = await handleVerifyEmail(email || '');
  //     setIsCheking(true);
  //     const isValid = verify.result?.result === 'valid';
  //     setIsError(!isValid);
  //     return;
  //   } catch (error) {
  //     setIsCheking(false);
  //     console.error('Erro na solicitação!, tente novamente mais tarde!');
  //   }
  // };

  const handleOpenTerms = () => {
    setIsOpenTerm(true);
    setIsAcceptTerm(true);
  };

  const handleSubmit = () => {
    setTimeout(() => {
      setIsOpenTootip(false);
      setErrorName('');
      setErrorEmail('');
    }, 1500);
    
    console.log('data', data);
    console.log('errors', errors);
    setErrorName('');
    setErrorEmail('');
    if (!data.name) setErrorName(errorMessages.name.required);
    if (!data.email) setErrorEmail(errorMessages.email.required);
    if (!data.name && !data.email) {
      setFocus('name');
    }
    if (!isAcceptTerm) {
      setIsOpenTootip(true);
      return;
    }
    setIsOpenTootip(false);
    // onVerifyEmail(data.email);

    if (
      data.email &&
      data.name &&
      !errors.email?.message &&
      !errors.name?.message
    ) {
      setTimeout(() => {
        onSubmit(data, !isError);
      }, 300);
    }
  };

  const onFocus = () => {

    if (!!errors.name?.message || !data.name) {
      setFocus('name');
      return;
    }
    if (!!errors.email?.message || !data.email) {
      setFocus('email');
      return;
    }
    if(!data.name && !data.email) {
      setFocus('name');
    }
  };

  const onBlur = () => {
    setErrorName('');
    setErrorEmail('');
    onFocus();
  };

  return (
    <ContainerMain>
      <ContainerLogo>
        <img src={LogoIcon} alt='logo' />
      </ContainerLogo>
      <Header isCompact>
        <div className='info'>
          <p>Encontramos o cartão perfeito para você!</p>
          <span className='center'>
            Preencha seus dados e receba por e-mail o cartão ideal para o seu
            perfil.
          </span>
        </div>
      </Header>
      <Body>
        <Form>
          <Input
            type='text'
            label='Digite seu primeiro nome'
            placeholder='Insira seu nome aqui'
            {...register('name')}
            error={errors?.name?.message || errorName}
            onBlur={onBlur}
          />

          <Input
            type='email'
            label='Digite seu e-mail principal'
            placeholder='Insira seu melhor e-mail aqui'
            {...register('email')}
            error={errors.email?.message || errorEmail}
            isError={isError}
            isCheking={isCheking}
            onBlur={onBlur}
          />

          <div className='form-group'>
            <div className='terms'>
              <Check
                isChecked={isAcceptTerm}
                isOpen={isOpenTootip}
                onClick={() => setIsAcceptTerm(!isAcceptTerm)}
              />
              <span onClick={handleOpenTerms}>
                Ao prosseguir você concorda com os{' '}
                <span>Termos de Serviço</span>
              </span>
            </div>
          </div>
          <div className='form-group'>
            <ButtonForm type='button' onClick={handleSubmit} />
          </div>
          {isOpenTerm && <Terms />}
        </Form>
      </Body>
    </ContainerMain>
  );
}
