import styled from 'styled-components';
import { ProgressBarStyleProps } from './types';
import { colors } from '../../styles';

export const ProgressBarContainer = styled.div<ProgressBarStyleProps>`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        gap: 8px;
        width: 100%;
        
        span{
            color: ${colors.grayMedium};
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
        }

        .progress-total{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            width: 100%;
            height: 9px;
            gap: 10px;
            border-radius: 30px;
            background-color: ${colors.grayLight};
        } 

        .progress-step{
            width: ${p=> p.progress}%;
            height: 9px;
            flex-shrink: 0;
            border-radius: 20px;
            background-color: ${colors.primary};
        } 

`;

