import styled from 'styled-components';
import { colors } from '../../styles';

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    max-width: 384px;
    position: relative;
    
    .label {
      color: ${colors.blackDark};
      font-family: Inter;
      font-size:  16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2%;
    }
    input {
      display: flex;
      align-items: center;
      width: 100%;
      padding:  15px 16px;
      border-radius: 4px;
      border: 1px solid ${colors.gray};
      background-color: ${colors.white};
      font-size: 16px;
    }
    .error {
      color: ${colors.danger};
      text-transform: lowercase;
    }
    .input-wrapper {
      position: relative;
      width: 100%;

      .input-status {
        position: absolute;
        right: 10px;
        top: 16px;
      }
    }
    .opcional{
      color: ${colors.grayMedium};
      font-family: Inter;
      font-size:  16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2%;
    }
`;

