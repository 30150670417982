import {
  Body,
  BodyWrap,
  ContainerLogo,
  ContainerMain,
  Header,
} from './style';
import { LogoIcon } from '../../assets';
import { Button, ProgressSteps } from '../../components';
import { Step2Props } from './types';

export default function Step2(props: Step2Props) {
  const { onClick, step } = props;

  return (
    <ContainerMain>
      <ContainerLogo>
        <img src={LogoIcon} alt='logo' />
      </ContainerLogo>
      <Header>
        <div className='info'>
          <p>Descubra qual é o cartão de crédito ideal para você</p>
          <span>Responda as perguntas a seguir, leva menos de 1 minuto</span>
        </div>
        <div className='progress'>
          <ProgressSteps currentStep={step} />
          <h1>Você está negativado?</h1>
        </div>
      </Header>
      <Body isRow>
        <BodyWrap>
          <Button onClick={() => onClick('yes')} />
          <Button isDanger onClick={() => onClick('no')} />
        </BodyWrap>
      </Body>
    </ContainerMain>
  );
}
