import axios, { AxiosError } from 'axios';

const BASE_URL = window.location.host.includes('localhost') ? 'https://api.ocontabil.com/api/v1.0' : 'https://api.ocontabil.com/api/v1.0'

const api = axios.create({ baseURL: BASE_URL });

api.interceptors.request.use(
    config =>  Promise.resolve(config),
    async (error: AxiosError) => Promise.reject(error),
);

api.interceptors.response.use(
    (res) => Promise.resolve(res),
    async (error: AxiosError) => Promise.reject(error),
);

export default api;