import { colors } from "../../styles";

export default function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
    const { color = colors.danger, width=58, height=57 } = props;
    return (
        <svg  width={width} height={height} viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M37.3604 10.2576C34.7411 8.96714 31.7931 8.24219 28.6758 8.24219C17.8091 8.24219 9 17.0513 9 27.918C9 38.7846 17.8091 47.5938 28.6758 47.5938C39.5424 47.5938 48.3516 38.7846 48.3516 27.918C48.3516 25.3301 47.852 22.859 46.944 20.5957L44.7905 22.9286C45.2782 24.5054 45.5407 26.181 45.5407 27.918C45.5407 37.2322 37.99 44.7829 28.6758 44.7829C19.3615 44.7829 11.8108 37.2322 11.8108 27.918C11.8108 18.6037 19.3615 11.053 28.6758 11.053C31.0492 11.053 33.3082 11.5433 35.3569 12.4281L37.3604 10.2576Z" fill={color}/>
            <path d="M20.2424 36.3502L45.5399 11.0527" stroke={color} strokeWidth="3.37299"/>
            <path d="M37.1074 36.3493L23.0533 19.4844" stroke={color} strokeWidth="3.37299"/>
        </svg>
    )
}