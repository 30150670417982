export const links = {
    yes:{
        facebook:'https://ocontabil.com/convite/superdigital/?utm_source=facebookpouso&amp;utm_medium=pousofacebook&amp;utm_campaign=superdigital_pouso',
        tiktok:'https://ocontabil.com/convite/superdigital/?utm_source=tiktokadspouso&amp;utm_medium=pousotiktok&amp;utm_campaign=superdigital_pouso',
        google:'https://ocontabil.com/convite/superdigital/?utm_source=googlepouso&amp;utm_medium=pousogoogle&amp;utm_campaign=superdigital_pouso',
    },
    no:{
        facebook:'https://ocontabil.com/convite/pan/?utm_source=facebookpouso&amp;utm_medium=pousofacebook&amp;utm_campaign=bancopan_pouso',
        tiktok:'https://ocontabil.com/convite/pan/?utm_source=tiktokadspouso&amp;utm_medium=pousotiktok&amp;utm_campaign=bancopan_pouso',
        google:'https://ocontabil.com/convite/pan/?utm_source=googlepouso&amp;utm_medium=pousogoogle&amp;utm_campaign=bancopan_pouso',
    },
    terms: 'https://ocontabil.com/termos-de-uso/',
    disclaimer: 'https://ocontabil.com/disclaimer/',
    privacy: 'https://ocontabil.com/politica-de-privacidade/'
}

