import { ProgressIndicatorContainer, ProgressIndicatorItem } from './style';
import { ProgressIndicatorProps } from './types';

export default function ProgressIndicator(props: ProgressIndicatorProps) {
  const { currentStep, totalSteps = 3 } = props;

  return (
    <ProgressIndicatorContainer>
      {Array.from({ length: totalSteps }, (_, i) => i).map((item) => (
        <ProgressIndicatorItem key={item} isActived={currentStep - 1 === item} />
      ))}
    </ProgressIndicatorContainer>
  );
}
