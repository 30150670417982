import { Iframe } from './style';

export default function Terms() {
  return (
    <Iframe>
      <h2>Termos de Uso</h2>
      <div className='body'>
        <p>
          <b>Termos e Condições Gerais de Uso&nbsp;</b>
        </p>
        <p>
          LEIA COM ATENÇÃO OS SEGUINTES TERMOS E CONDIÇÕES DE USO, POIS, AO
          UTILIZAR A PLATAFORMA, VOCÊ (“USUÁRIO”) EXPRESSA SUA CONCORDÂNCIA COM
          OS MESMOS.&nbsp;
        </p>
        <p>
          Por favor, atente-se cuidadosamente aos seguintes termos e condições
          de uso. Ao utilizar a plataforma, você (“Usuário”) expressa sua
          concordância com os mesmos. Estes termos podem ser acessados a
          qualquer momento através deste link:
          https://ocontabil.com/termos-de-uso/, juntamente com a Política de
          Privacidade do O CONTÁBIL disponível em:
          https://ocontabil.com/politica-de-privacidade/, além da legislação em
          vigor.&nbsp;
        </p>
        <ol start={1}>
          <li>
            <b> Definições&nbsp;</b>
          </li>
        </ol>
        <p>Para os propósitos destes Termos e Condições Gerais de Uso:&nbsp;</p>
        <p>
          Usuário: qualquer pessoa física ou jurídica que acesse ou utilize a
          plataforma, por meio de diversos dispositivos como aparelhos móveis,
          celulares, tablets, computadores pessoais, navegadores de internet,
          entre outros.&nbsp;
        </p>
        <ol start={2}>
          <li>
            <b> Aceitação&nbsp;</b>
          </li>
        </ol>
        <p>
          Ao acessar o O CONTÁBIL, o Usuário concorda em ter lido e aceitado os
          Termos e Condições Gerais de Uso e a Política de Privacidade do O
          CONTÁBIL. O Usuário também declara ter no mínimo 18 anos e capacidade
          plena para aceitar esses termos e condições.&nbsp;
        </p>
        <p>
          Ao aceitar esses termos e a política de privacidade, seja em seu nome
          ou em nome de uma empresa, empregador, cliente ou outra entidade, você
          estará concordando e ficando vinculado ao que está apresentado aqui,
          constituindo assim um contrato válido entre você e o O CONTÁBIL. Se
          não concordar com qualquer um dos termos e condições, por favor, não
          utilize nossa página.&nbsp;
        </p>
        <ol start={3}>
          <li>
            <b> Propósito do O CONTÁBIL&nbsp;</b>
          </li>
        </ol>
        <p>
          O CONTÁBIL, com endereço https://ocontabil.com/, é uma plataforma
          dedicada à recomendação de produtos e serviços financeiros, com foco
          na criação de conteúdos informativos e análises detalhadas de cartões
          de crédito, bancos digitais, empréstimos, financiamentos, entre
          outros. O trabalho é feito em parceria com diversas empresas e
          instituições financeiras para fornecer informações úteis aos Usuários,
          oferecendo insights valiosos para auxiliá-los a encontrar o produto
          que melhor atenda às suas necessidades.
        </p>
        <ol start={4}>
          <li>
            <b> Disponibilidade e Responsabilidades&nbsp;</b>
          </li>
        </ol>
        <p>
          O O CONTÁBIL dedica-se incansavelmente à manutenção de informações
          precisas e atualizadas em sua plataforma online. Entretanto, é
          importante ressaltar que não podemos assumir responsabilidade por
          quaisquer danos decorrentes da presença de softwares maliciosos. Por
          isso, recomendamos enfaticamente que cada Usuário assuma a
          responsabilidade de manter seu equipamento devidamente atualizado e
          protegido, garantindo assim uma experiência segura e satisfatória
          durante a utilização de nossos serviços.&nbsp;
        </p>
        <ol start={5}>
          <li>
            <b> Links para Páginas de Terceiros&nbsp;</b>
          </li>
        </ol>
        <p>
          O O CONTÁBIL pode disponibilizar links para páginas de terceiros,
          porém é imprescindível ressaltar que a utilização dessas páginas é de
          inteira responsabilidade do Usuário. É essencial que o Usuário esteja
          ciente e observe atentamente os termos e condições de uso, bem como as
          políticas de privacidade de cada uma dessas páginas. Importante
          destacar que não assumimos qualquer responsabilidade pelo conteúdo ou
          pela qualidade dos produtos/serviços oferecidos por terceiros.&nbsp;
        </p>
        <ol start={6}>
          <li>
            <b> Proibições e Responsabilidades dos Usuários&nbsp;</b>
          </li>
        </ol>
        <p>
          Ao Usuário, é terminantemente vedado realizar qualquer ação que vá de
          encontro à legislação vigente ou aos princípios morais estabelecidos,
          assim como infringir os direitos de terceiros. É imperativo que o
          Usuário respeite os direitos de propriedade intelectual e se abstenha
          de tentar acessar áreas restritas do site sem autorização prévia e
          expressa.&nbsp;
        </p>
        <ol start={7}>
          <li>
            <b> Propriedade Intelectual&nbsp;</b>
          </li>
        </ol>
        <p>
          É importante ressaltar que todo o material presente neste site é de
          propriedade exclusiva do O CONTÁBIL e encontra-se protegido pela
          legislação brasileira de propriedade intelectual. Qualquer utilização
          não autorizada, incluindo reprodução, distribuição ou modificação, é
          expressamente proibida e sujeita às penalidades previstas em
          lei.&nbsp;
        </p>
        <ol start={8}>
          <li>
            <b> Alterações dos Termos de Uso&nbsp;</b>
          </li>
        </ol>
        <p>
          Reservamo-nos o direito de efetuar alterações nestes Termos de Uso a
          qualquer momento, e tais modificações entrarão em vigor imediatamente
          após sua publicação no site. Aconselhamos o Usuário a revisar
          periodicamente estes termos, a fim de estar sempre atualizado sobre
          quaisquer mudanças realizadas. Essa prática garantirá que o Usuário
          esteja plenamente ciente das condições atuais de uso da
          plataforma.&nbsp;
        </p>
        <ol start={9}>
          <li>
            <b> Disposições Gerais&nbsp;</b>
          </li>
        </ol>
        <p>
          Estes termos de uso são regidos pelas leis brasileiras, com o Foro da
          Cidade de Aracaju/SE competente em ações envolvendo o O CONTÁBIL. O
          usuário concorda com essa
        </p>
        <p>
          competência e renuncia a qualquer outro foro. Estes termos de uso
          devem ser interpretados juntamente com a Política de Privacidade,
          ambos regidos pelas leis brasileiras, incluindo a Constituição
          Federal, o Código Civil, o Código de Defesa do Consumidor, o Marco
          Civil da Internet e a Lei Geral de Proteção de Dados.&nbsp;
        </p>
        <p>
          Este documento apresenta os Termos e Condições Gerais de Uso para o O
          CONTÁBIL. Ajustes adicionais podem ser feitos conforme necessário para
          atender às especificidades do site.
        </p>
      </div>
    </Iframe>
  );
}
