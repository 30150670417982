import { createGlobalStyle } from "styled-components";
import { breakpoints, colors } from ".";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    font-family: "Inter";
    font-weight: 400;
    appearance: none;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html{
    box-sizing: border-box;
  }
  body{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    padding: 20px 16px;
    overflow-x: hidden;
    height: 100vh;

    @media (min-width: ${breakpoints.md}px) { 
        padding: 66px 185px 34px 185px;
    }
  }
  #root{
  }
  
  button{
    cursor: pointer;
  }
`