import styled from 'styled-components';
import { colors } from '../../styles';
import { ProgressIndicatorStyleProps } from './types';

export const ProgressIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const ProgressIndicatorItem = styled.div<ProgressIndicatorStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${(p) =>
    p.isActived ? colors.greenDarker : colors.graySecundary};
`;
