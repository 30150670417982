import styled from 'styled-components';
import { ButtonProps } from './types';
import { colors } from '../../styles';

export const ButtonContainer = styled.button<Pick<ButtonProps, 'isDanger'>>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 180px;
    padding: 16px 32px;
    border-radius: 4px;
    background-color: ${colors.gray10};
    border: 1px solid ${p => p.isDanger ? colors.danger : colors.greenDarker};

    span{
        color: ${colors.grayDarker};
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.4px;
    }
`;

