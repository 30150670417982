import Tooltip from '../tooltip';
import { CheckContainer, CheckWrapper } from './style';
import { CheckProps } from './types';

export default function Check({
  isDisabled = false,
  isChecked,
  isOpen,
  onClick,
}: CheckProps) {
  return (
    <CheckWrapper>
      {isOpen && !isChecked && (
        <Tooltip width='250px' title='Selecione esta caixa de seleção' />
      )}

      <CheckContainer
        isChecked={isChecked}
        disabled={isDisabled}
        onClick={onClick}
      >
        {isChecked ? <div id='check' /> : <></>}
      </CheckContainer>
    </CheckWrapper>
  );
}
