import axios, { AxiosError } from 'axios';

const activeCampainApi = axios.create({ baseURL: process.env.ACTIVE_CAMPAIN_API_URL });

activeCampainApi.interceptors.request.use(
    config =>  Promise.resolve(config),
    async (error: AxiosError) => Promise.reject(error),
);

activeCampainApi.interceptors.response.use(
    (res) => Promise.resolve(res),
    async (error: AxiosError) => Promise.reject(error),
);

export default activeCampainApi;