import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Home } from '../pages';

export default function RoutesComponent() {
    
    return(
        <BrowserRouter>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:slug" element={<Home />} />
         </Routes>
        </BrowserRouter>
    )
}