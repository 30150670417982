import { ButtonFormContainer } from "./style";
import { ButtonFormProps } from "./types";

export default function ButtonForm({ title = 'VER MEU CARTÃO',isLoading, disabled, ...rest }: ButtonFormProps) {
  
  return (
    <ButtonFormContainer disabled={isLoading || disabled}  {...rest}>
      {
        isLoading ? (
          <div className="load"></div>
        ):title
      }
    </ButtonFormContainer>
  );
}
