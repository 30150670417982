import styled from 'styled-components';
import { CheckProps } from './types';
import { colors } from '../../styles';

export const CheckWrapper = styled.div`
    position: relative;
`;

export const CheckContainer = styled.button<Pick<CheckProps, 'isChecked'>>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: ${colors.gray10};
    border: 1px solid ${p => p.isChecked ? colors.greenDarker: colors.gray};
    #check{
        display: inline-block;
        transform: rotate(45deg);
        height: 10px;
        width: 4px;
        border-bottom: 1px solid ${colors.greenDarker};
        border-right: 1px solid ${colors.greenDarker};
    }
`;

