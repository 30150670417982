import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalStyle from './styles/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutesComponent from './routes';

export const queryClient = new QueryClient({});

const gtmId: any = {
  'responda.ocontabil.com': 'GTM-W3W6DS5S',
  'respondastaging.ocontabil.com': 'GTM-TR6Q532Q'
}[window.location.hostname]

TagManager.initialize({
  gtmId
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <RoutesComponent />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </QueryClientProvider>
  );
}
