import { CheckIcon, CloseIcon } from "../../assets";
import { ButtonContainer } from "./style";
import { ButtonProps } from "./types";

export default function Button({ title, isDanger, ...rest }: ButtonProps) {
  const defaultTitle = isDanger ? 'Não' : 'Sim';
  const formatedTitle = (title ? title: defaultTitle);
  return (
    <ButtonContainer isDanger={isDanger} {...rest}>
      {
        isDanger ? <CloseIcon/> :<CheckIcon />
      }
      <span>{formatedTitle}</span>
    </ButtonContainer>
  );
}
