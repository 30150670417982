import styled from 'styled-components';
import { TooltipProps } from './types';

export const TooltipContainer = styled.span<Pick<TooltipProps,'left'|'bottom'|'width'>>`
        width: ${p=>p.width || 'auto'};
        background-color: white;
        color: #000;
        text-align: center;
        border-radius: 5px;
        padding: 0.5em;
        position: absolute;
        z-index: 1;
        margin-bottom: -4px;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.08);

        bottom: ${p=>p.bottom || 150}%;
        left: ${p=>p.left || 50}%;
        transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px); 
        -webkit-transform: translate(-50%, 0px);
        :after {
            border-width: 5px;
            border-style: solid;
            content: "";
            position: absolute;
        }

        ::after {
            border-color: white transparent transparent transparent;
            top: 100%;
            left: 50%;
            margin-left: -5px;
        }
`;

